@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background-image: url('./assets/background.webp');
	background-size: contain;
	/* background-repeat: repeat; */
	background-size: 650px 375px;
	/* background-position: center; */
	overflow-x: hidden;
	background-color: gray;
}
