@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loading-spinner {
	width: 50px;
	height: 50px;
	border: 10px solid rgb(147 197 253); /* Light grey */
	border-top: 10px solid rgb(253 224 71); /* Black */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}
